body {
    background: #343a40;
    color: whitesmoke;
}

.effects-container{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    margin-top: 45px;
}

.overlay-top{
    position: absolute;
    top: 0;
    height: 2rem;
    background-color: lightgray;
    width: 100%;
}

.top-right{
    position: fixed;
    right: 0;
}

.top-left{
    position: fixed;
    left: 0;
}

.status-bar{
    display: flex;
}

.colorpicker{
    background: gray;
    border-radius: 10px;
    margin-bottom: 20px;
}

.brightness-wrapper{
    margin-top: 35px;
    width: 60%;
    max-width: 320px;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
}

.brightness-slider{
    width: 100%;
}

input[type=range]::-webkit-slider-thumb {
    border: 1px solid #00001E;
    height: 27px;
    width: 26px;
    border-radius: 15px;
    background: #FFFFFF;
    cursor: pointer;
    -webkit-appearance: none;
  }
  .brightness-label{
      color: whitesmoke !important;
      font-size: 14px;
      font-weight: 800;
  }

  .brightness-label-title{
    color: whitesmoke !important;
    margin-bottom: 5px;
  }