/* Color-Variables */

$primary: #FA5F00;
$primary_text: #E37D0B;
$primary_opacity: rgba(250, 96, 0, 0.5);
$secondary: #FDA80D;
$tertiary: #FD230D;
$tertiary_text: #E33F0B;
$bg-overlay: #343a40;

$red: #FD230D;
$yellow: #FDA80D;

/* CSS */

.notificationBar {
    background-color: rgba(0, 0, 0, 0.2);
    color: white;

    text-align: center;
    padding: 19px;
  
    position: fixed;
    bottom: 0px;
    right: 0px;
  
    font-weight: normal;
    font-size: medium;

    width: calc(100%);
    z-index: 9999;
  }

  .noShow{
    animation: notificationPopOut 500ms;
    animation-fill-mode: forwards;

  }
  
  .showNotification {
  
    animation: notificationPopUp 500ms;
    animation-fill-mode: forwards; 
  }

  @keyframes notificationPopUp {
    from {transform: translateY(100%);}
    to {transform: translateY(0px);}
  }

  @keyframes notificationPopOut {
    from {transform: translateY(0px);}
    to {transform: translateY(100%);}
  }

  .NotificationFirstEntry {
      opacity: 0;
  }

  .error {
      background: #FA5F00;
      color: white;
  }

  .instagram {
    background: #f09433; 
    background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); 
    background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
    background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
  }

  .success {
      background:#2ecc71;
      color: white;
  }

