/* Color-Variables */

$primary: #FA5F00;
$primary_text: #E37D0B;
$primary_opacity: rgba(250, 96, 0, 0.5);
$secondary: #FDA80D;
$tertiary: #FD230D;
$tertiary_text: #E33F0B;
$bg-overlay: #343a40;

$red: #FD230D;
$yellow: #FDA80D;

/* CSS */

@import url('https://fonts.googleapis.com/css?family=Montserrat|Ubuntu');

.login-element{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 85%;
    max-width: 400px;
    background: white;
    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 10px;
}

.not-found-element{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 85%;
    max-width: 400px;
    background: white;
    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 10px;
    color: #343a40;
}

.not-found-element h1{
    text-align: center;
    font-size: xx-large;
}

.not-found-element h2{
    text-align: center;
    padding: 20px 0 20px 0;
}

.not-found-element form{
    padding: 0 40px 20px 40px;
    box-sizing:  border-box;
}

.not-found-element button {
    margin: 10px 0;
    background-color: $primary;
}

.not-found-element button:hover {
    margin: 10px 0;
    background-color: $tertiary_text;
}

.not-found-element .reset-link {
    color: $primary_text;
}

.not-found-element .backToLogin {
    position: absolute;
    left: 5px;
    top: 5px;
    color: $primary_text !important;
}

.not-found-element .backToLogin i {
    position: relative;
    left: 7px;
    top: 7px;
}

span{
    color:rgba(0,0,0,.5) !important;
}


.login-element h1{
    text-align: center;
    font-size: xx-large;
    color: #878788;
}

.login-element h2{
    text-align: center;
    padding: 20px 0 20px 0;
}

.login-element form{
    padding: 0 20px 20px 20px;
    box-sizing:  border-box;
}

.login-element button {
    margin: 10px 0;
    background-color: $primary;
}

.login-element button:hover {
    margin: 10px 0;
    background-color: $tertiary_text;
}

.login-element .reset-link {
    color: $primary_text;
}

.login-element .backToLogin {
    position: absolute;
    left: 5px;
    top: 5px;
    color: $primary_text;
}

.login-element .backToLogin i {
    position: relative;
    left: 7px;
    top: 7px;
}

.login-element form .error-message {
    display: block;
    color: red;
    margin: 5px 0;
    text-align: center;
}

.login-element .MuiCheckbox-colorPrimary.Mui-checked {
    color: $primary!important;
}

.login-element .MuiFormLabel-root.Mui-focused {
    color: $tertiary_text!important;
}

.login-element .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: $tertiary_text!important;
}

form .txt_field{
    position: relative;
    border-bottom: 2px solid #adadad;
    margin: 30px 0;
}

.status-element {
    padding: 40px;
}

.copyright,
.copyright a {
    color: rgba(0, 0, 0, 0.5);
    text-align: center;
    font-size: 14px;
}

.instagram {
    background: #f09433; 
    background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); 
    background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
    background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
  }

.bg-element {
    width: 100%;
    height: 100vh;
    background-color: #f0f0f0;
}